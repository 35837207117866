// Colors
$dark-grey: #333;
$grey: #666;
$blue: #1c88bf;
$background: #f8f8f8;
$blue-light: #76cefa;
$surface-dark: #d6eafa;
$surface: #e6f4ff;
$dark-blue: #364051;
$off-white: #f7f7f7;
$heading: #e9305b;
$royal-blue: #156ef4;
$primaryButton: #16d2aa;
// Font Size
$font-size-large: 1.8rem;
$font-size-small: 1.4rem;
// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;
