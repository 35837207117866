.list-header {
  background: $off-white;
  border: 1px solid darken($off-white, 7%);
  color: $grey;
  display: flex;
  justify-content: space-between;
  padding: $s-size $m-size;
}

.list-body {
  margin-bottom: $m-size;
  @media (min-width: $desktop-breakpoint) {
    margin-bottom: $l-size;
  }
}

.list-item {
  border: 1px solid darken($off-white, 7%);
  border-top: none;
  color: $dark-grey;
  display: flex;
  flex-direction: column;
  padding: $s-size;
  text-decoration: none;
  transition: background .3s ease;
  &:hover {
    background: $off-white;
  }
  @media (min-width: $desktop-breakpoint) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: $m-size;
  }
}

.list-item--message {
  align-items: center;
  color: $grey;
  justify-content: center;
  padding: $m-size;
  &:hover {
    background: none;
  }
}

.list-item__title {
  margin: 0;
  word-break: break-all;
}

.list-item__sub-title {
  color: $grey;
  font-size: $font-size-small;
}

.list-item__data {
  margin: $s-size 0 0 0;
  @media (min-width: $desktop-breakpoint) {
    margin: 0;
    padding-left: $s-size;
  }
}
