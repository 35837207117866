* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  color: $dark-grey;
  font-family: Helvetica, Arial, Helvetica, sans-serif;
  font-size: $m-size;
  line-height: 1.6;
}

button {
  cursor: pointer;
}

button:disabled {
  cursor: default;
}

.is-active {
  font-weight: bold;
}

.question-container {
  z-index: 10000000;
  height: 80%;
  width: 100%;
  border-radius: 5px;
  padding: 1%;
  display: inline-block;
}

.question-text {
  height: 20%;
  width: 96%;
}

.jobs-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.jobs-list {
  order: 0;
  width: 100%;
  // width: 66%;
  // border: solid blue 1px;
}

.chat-container {
  display: inline-block;
  min-width: 40%;
  height: 400px;
  margin: 1% 1% 0 0;
  order: 1;
  border: red solid 3px;
}

.chat-contacts {
  float: left;
  width: 30%;
  height: 100%;
  order: 0;
  border: solid green 1px;
}

.chat-messages {
  float: right;
  position: relative;
  width: 70%;
  height: 100%;
  overflow-y: scroll;
  order: 1;
  border: solid blue 1px;
}

.spacer {
  flex: 1;
}

.user-image {
  border-radius: 50%;
  box-shadow: 0 2px 4px -1px #222;
  user-select: none;
  -webkit-user-drag: none;
}
