.myJobOffers {
  // border: solid grey 2px;
  // width: 60%;
  width: 98%;
  // min-width: 60%;
  height: 4%;
  margin: 1%;
  padding: 1%;
  display: inline-block;
}

.offerList-title {
  text-align: center;
  font-weight: bold;
  margin: 0;
}

.offerList-numbers {
  display: inline-block;
  text-align: center;
  padding: 0 3% 0 3%;
}

.offerList-description {
  display: inline-block;
  padding: 0 5% 0 3%;
  margin: 0;
}

.offerList-long {
  margin: 0 0 0 3%;
}

.offerList-button {
  float: right;
}

.comment-submit {
  font-size: 0.8rem;
  margin: 5px;
  box-shadow: 0 2px 4px -1px #333;
  border-radius: 5px;
  font-size: 14px;
}

.question-button {
  margin: 5px;
  background: white;
  box-shadow: 0 2px 4px -1px #333;
  border-radius: 5px;
}

.comment-submit.submit {
  background-color: green;
  color: white;
}
.comment-submit.cancel {
  background-color: gray;
  color: white;
}

.jobListWrapper {
  width: 95%;
  padding: 1%;
  margin: 1%;
}
