header.header {
  position: relative;
  width: 100%;
  height: 130px;

  .content-container {
    position: fixed;
    background-color: white;
    width: 100%;
    height: auto;
    padding: 0 5vw;
    margin: 0px;
    max-width: 100%;
    z-index: 100;
    padding-bottom: 10px;

    .sm-allowed-content {
      display: none !important;
    }

    .sm-hidden-content {
      display: block !important;
    }

    .header__content {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;

      .button-action-wrapper {
        cursor: pointer;
      }

      .header__title {
        padding: 5px;
        outline: none;
        text-decoration: none;

        .logo {
          width: auto;
          height: 60px;
          display: flex;
          flex-direction: row;
          align-items: center;

          .logo-image {
            height: 100%;
            width: auto;
            margin: 0px 10px;
            margin-left: 0px;
          }

          .logo-text {
            text-transform: uppercase;
            .logo-main {
              font-size: 20px;
              margin: 0px;
            }

            .logo-sub {
              text-align: center;
              font-size: 12px;
            }
          }
        }
      }

      .links {
        padding: 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;

        .button--link {
          font-size: 14px;
          margin: 0px 5px;
          color: $dark-grey;
          font-weight: bold;
        }
      }

      .choose-gender {
        position: relative;
        right: 0px;
        padding: 5px 10px;
      }
    }

    .sub_header__content {
      display: flex !important;
      flex-direction: row;
      align-items: center;
      text-transform: capitalize;
      background: $surface;
      padding: 3px 5px;
      border-radius: 15px;

      .userName {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        margin-left: 15px;

        .header__title-message {
          text-transform: capitalize;
          font-size: 16px;
          margin: 5px 10px;
        }
      }

      .user-image {
        width: 40px;
        height: 40px;
        border-radius: 50px;
      }

      .actions {
        list-style-type: none;
        margin: 0px;
        padding: 0px 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;

        .action {
          margin: 5px 25px;
          float: left;
          border-radius: 10px;

          button {
            background: $dark-blue;
            color: white;
          }
        }

        .logout {
          padding: 7px 10px;
          font-size: 14px;
          display: inline-block;
          font-weight: 400;
          text-align: center;
          vertical-align: middle;
          user-select: none;
          line-height: 1.3;
          transition: color 0.15s ease-in-out,
            background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
        }
      }
    }
  }

  .sidebar-header {
    background: $surface;
    color: $dark-blue;
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100vh;
    z-index: 200;
    max-width: 300px;
    width: 80%;
    border-right: 1px solid white;
    box-shadow: 0 2px 4px 1px #4444;
    display: none;
    flex-direction: column;

    .header-app-logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .header__title {
        padding: 5px;
        outline: none;
        text-decoration: none;

        .logo {
          width: auto;
          height: 60px;
          display: flex;
          flex-direction: row;
          align-items: center;

          .logo-image {
            height: 100%;
            width: auto;
            margin: 0px 10px;
            margin-left: 0px;
          }

          .logo-text {
            text-transform: uppercase;
            .logo-main {
              font-size: 20px;
              margin: 0px;
            }

            .logo-sub {
              text-align: center;
              font-size: 12px;
            }
          }
        }
      }

      .menu-close-action {
        padding: 10px;

        .button-action-wrapper {
          padding: 5px;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          font-size: 20px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          outline: none;
          background: white;
        }
      }
    }

    .user-type-selection {
      margin: 10px;
      margin-bottom: 5px;
      padding: 5px 10px;
      border-radius: 50px;
      border: 1px solid white;
      width: auto;
    }

    .sidebar-items-container {
      position: relative;
      flex: 1;
      top: 0px;
      list-style-type: none;
      margin: 0px;
      padding: 0px;
      height: 100%;
      overflow: hidden;
      overflow-y: auto;

      .sidebar-item {
        margin: 10px;
        font-size: 14px;
        display: flex;
        flex-direction: row;
        padding-left:15px;
        // justify-content: center;
        // left:0;

        button,
        a {
          font-size: 14px;
          font-weight: 600;
          background: transparent;
          box-shadow: none;
          margin: 0px;
          padding: 0px;
          border-radius: 0px;
          padding: 10px;
          text-align: left;
          box-sizing: border-box;
          text-decoration: none;
          outline: none;
          color: $dark-blue;
          text-transform: capitalize;
          width: 90%;
          border-bottom: 1px solid white;
        }

        a:hover {
          background: #ffffff80;
          color: $dark-blue;
        }

        button:hover {
          background: #ffffff80;
        }
      }

      .sidebar-item.user-info-item {
        padding: 5px 10px;

        .userName {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          margin-left: 5px;

          .header__title-message {
            text-transform: capitalize;
            font-size: 16px;
            margin: 5px 10px;
          }
        }

        .user-image {
          width: 40px;
          height: 40px;
          border-radius: 50px;
        }
      }
    }
  }
}

@media screen and (max-width: 1050px) {
  header.header {
    height: 60px;

    .small-screen-account-btn {
      margin: 10px;

      .account-buttons > button {
        background: $dark-blue !important;

        &:active,
        &:focus {
          background: $dark-blue !important;
        }
      }
    }

    .content-container {
      .sm-allowed-content {
        display: block !important;
      }

      .sm-hidden-content {
        display: none !important;
      }

      .header__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        .header__title {
          padding: 5px 10px;
          outline: none;
          text-decoration: none;

          .logo {
            width: auto;
            height: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .logo-image {
              height: 100%;
              width: auto;
              margin: 0px 10px;
            }

            .logo-text {
              text-transform: uppercase;
              .logo-main {
                font-size: 15px;
                margin: 0px;
              }

              .logo-sub {
                text-align: center;
                font-size: 10px;
              }
            }
          }
        }

        .userName {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
          display: none;
        }

        .choose-gender {
          position: relative;
          right: 0px;
          padding: 5px 10px;
        }
      }

      .sub_header__content {
        display: none !important;
      }
    }

    .sidebar-header {
      display: flex;
    }
  }
}
