.form {
  display: flex;
  flex-direction: column;
  >* {
    margin-bottom: $m-size;
  }
}

.form__error {
  margin:  $m-size 0 0 0;
  font-style: italic;
  color: red
}

#jobForm {
  margin-left: 3%;
}