.button {
  background: $blue;
  border: none;
  color: white;
  display: inline-block;
  font-size: $font-size-large;
  font-weight: 300;
  line-height: 1;
  padding: $s-size;
  text-decoration: none;
}

.button--link {
  background: none;
}

.button--secondary {
  background: #888;
}

.btn-circle {
  border-radius: 50%;
}

.account-buttons {
  .account-button {
    button {
      width: 100%;
      text-align: left;
      background-color: transparent !important;
      color: $dark-blue !important;
      box-shadow: none !important;
      padding: 3px 0px !important;
    }

    &:active {
      button {
        color: white !important;
      }
      // background-color: transparent;
    }
  }
}
