button.explanation-button {
  border-radius: 50%;
  box-shadow: 0 2px 4px -1px #2222;
  width: 30px;
  height: 30px;
  outline: none;
  border: none;
  font-weight: bold;
  margin: 0px;
  background: white;
}

.popup-wrapper {
  .modal-header {
    width: 100%;
    .modal-title {
      width: 100%;
    }
  }

  .modal-content {
    width: 750px;
    transform: translateX(-15%);
  }

  .modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    button,
    button.btn.btn-secondary {
      margin: 10px;
      padding: 7px 20px !important;
      line-height: 1.6;
      font-size: 14px !important;
      font-weight: 600;
      border-radius: 5px;
    }

    div {
      padding: 0 5px;
      button,
      button[type="button"].popup-trigger-button.btn {
        margin: 10px;
      }
    }

    .btn.btn-primary {
      background: $primaryButton;

      &:focus,
      &:active {
        background: $primaryButton;
      }
    }

    .btn.btn-primary + .btn.btn-primary {
      background: #007bff;
    }

    .btn.btn-secondary {
      background: #eee;
      border: 1px solid lightgrey;
      color: $dark-blue;
    }
  }
}

button.btn {
  border: none;
  outline: none;
  font-size: 14px;
  text-transform: capitalize;
  padding: 5px 10px;
}

.modal-header {
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  box-sizing: border-box;

  .modal-title {
    width: 100%;
    text-align: center;
    font-size: 20px;
    padding: 10px;
    box-sizing: border-box;
  }
}

.modal-body {
  font-size: 16px;
  padding: 0px !important;

  & > div {
    padding: 10px 15px;
  }
}

textarea,
input {
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 2px 4px -1px #4444;
  border: 1px solid lightgray;
}

button.button--link.logout,
button,
button.action,
button.btn.popup-trigger-button,
button.dropdown-toggle.btn.btn-primary {
  border-radius: 5px;
  box-shadow: 0 2px 4px -1px #4444;
  padding: 5px 15px;
  cursor: pointer;
  border: none;
  text-transform: capitalize;
}

button.btn.btn-link {
  box-shadow: none;
}

@media screen and (max-width: 900px) {
  .popup-wrapper {
    .modal-header {
      width: 100%;
      .modal-title {
        width: 100%;
      }
    }

    .modal-content {
      width: auto;
      transform: translateX(0%);
    }
  }
}

@media screen and (max-width: 850px) {
  .popup-wrapper {
    .modal-header {
      .modal-title {
        font-size: 20px;
        margin: 0px;

        h1 {
          font-size: 20px;
          padding: 0px;
          margin: 0px;
        }

        & * {
          font-size: 20px;
          margin: 0px;
        }
      }
    }

    .modal-content {
      h1 {
        font-size: 18px;
      }

      & * {
        font-size: 16px;
      }

      .modal-body {
        font-size: 14px;

        * {
          font-size: 14px;
        }

        .line-head {
          font-size: 14px;
          font-weight: 600;
        }

        .line-content {
          font-size: 14px;
        }
      }
    }

    .modal-footer {
      font-size: 14px;

      * {
        font-size: 14px;
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button {
        margin: 5px !important;
      }

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        button {
          width: 200px;
        }
      }
    }
  }
}
